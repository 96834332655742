import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Card, Grid, Image } from 'semantic-ui-react';
import DefaultImageSVG from './placeholder.png';
import 'volto-facultycv/directory.css';

const FacultyCVBlockTemplate = ({ items, isEditMode, templateSelected, showImage, titleTag }) => {
  const TitleTag = titleTag ? titleTag : 'h2';
  // const dispatch = useDispatch();
  // const portal_type = ['faculty2', 'staff2', 'poe'];
  // const metadata_fields = ['dept', 'briefBio', 'officePhone', 'orgStatus', 'otherTitle', 'teachingTitle', 'officePhone', 'officeLocation', 'description'];
  // const metadata_fields = ['dept', 'briefBio', 'officePhone', 'orgStatus', 'otherTitle', 'teachingTitle', 'officePhone', 'officeLocation', 'description'];
  // useEffect(() => {
  //   dispatch(searchContent('', {
  //     portal_type: portal_type, metadata_fields: metadata_fields,
  //   }, 'items'));
  // }, [dispatch, portal_type, metadata_fields]);
  return (
    <>
      {templateSelected === 'list' &&
        items.map((item) => (
          <Grid key={item.title}>
            {showImage && (
              <Grid.Column width={1}>
                {!item.image_field ? (
                  <Link to={flattenToAppURL(item['@id'])} condition={!isEditMode}>
                    <Image className="portraitPhoto" src={DefaultImageSVG} alt="placeholder" size="small" />
                  </Link>
                ) : (
                  <img className="portraitPhoto" src={flattenToAppURL(`${item['@id']}/@@images/${item.image_field}/mini`)} alt={item.title ? item.title : ''} loading="lazy" />
                )}
              </Grid.Column>
            )}
            <Grid.Column width={11}>
              <TitleTag>
                <Link to={flattenToAppURL(item['@id'])} condition={!isEditMode}>
                  {item.title}
                </Link>
              </TitleTag>
              <p>{item.description}</p>
            </Grid.Column>
          </Grid>
        ))}

      {templateSelected === 'card' && (
        <Card.Group centered className="responsive-card-group">
          {items.map((item) => (
            <Card>
              {showImage && (
                <>
                  {!item.image_field ? (
                    <Link to={flattenToAppURL(item['@id'])} condition={!isEditMode}>
                      <Image className="portraitPhoto" src={DefaultImageSVG} alt="placeholder" />
                    </Link>
                  ) : (
                    <Image className="portraitPhoto" src={flattenToAppURL(`${item['@id']}/@@images/${item.image_field}/mini`)} ui={false} />
                  )}
                </>
              )}
              <Card.Content>
                <Card.Header>
                  <TitleTag>
                    <Link to={flattenToAppURL(item['@id'])} condition={!isEditMode}>
                      {item.title}
                    </Link>
                  </TitleTag>
                </Card.Header>
                <Card.Description>
                  {item.description.split(' -').map((part, index, array) =>
                    index === array.length - 1 ? (
                      part
                    ) : (
                      <>
                        {part}
                        <br />
                      </>
                    ),
                  )}
                </Card.Description>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      )}

      {templateSelected === 'grid' && (
        <Grid>
          <Grid.Row columns={2}>
            {items.map((item) => (
              <Grid.Column>
                <Grid>
                  {showImage && (
                    <Grid.Column width={4}>
                      {!item.image_field ? (
                        <Link to={flattenToAppURL(item['@id'])} condition={!isEditMode}>
                          <Image className="portraitPhoto" src={DefaultImageSVG} alt="placeholder" size="small" />
                        </Link>
                      ) : (
                        <img className="portraitPhoto" src={flattenToAppURL(`${item['@id']}/@@images/${item.image_field}/mini`)} alt={item.title ? item.title : ''} loading="lazy" />
                      )}
                    </Grid.Column>
                  )}
                  <Grid.Column width={8}>
                    <TitleTag>
                      <Link to={flattenToAppURL(item['@id'])} condition={!isEditMode}>
                        {item.title}
                      </Link>
                    </TitleTag>

                    <p>
                      {item.description.split(' -').map((part, index, array) =>
                        index === array.length - 1 ? (
                          part
                        ) : (
                          <>
                            {part}
                            <br />
                          </>
                        ),
                      )}
                    </p>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      )}

      {templateSelected === 'hero' &&
        items.map((item) => (
          <div className="block hero">
            <div className="block-inner-wrapper">
              {showImage && (
                <>
                  {!item.image_field ? (
                    <Link to={flattenToAppURL(item['@id'])} condition={!isEditMode}>
                      <Image className="portraitPhoto" src={DefaultImageSVG} alt="placeholder" />
                    </Link>
                  ) : (
                    <Image src={flattenToAppURL(`${item['@id']}/@@images/${item.image_field}/mini`)} alt={item.title && item.title} className="hero-image portraitPhoto" loading="lazy" />
                  )}
                </>
              )}

              <div className="hero-body">
                <div className="hero-text">
                  <TitleTag>
                    <Link to={flattenToAppURL(item['@id'])} condition={!isEditMode}>
                      {item.title}
                    </Link>
                  </TitleTag>

                  <p className="documentDescription">
                    {item.description.split(' -').map((part, index, array) =>
                      index === array.length - 1 ? (
                        part
                      ) : (
                        <>
                          {part}
                          <br />
                        </>
                      ),
                    )}
                  </p>
                </div>
                {/*<div className='link-more'>*/}
                {/*  <Link to={flattenToAppURL(item['@id'])}>View {item.title} Profile</Link></div>*/}
              </div>
            </div>
          </div>
        ))}

      {templateSelected === 'segmented' && items.some((item) => item.Description.includes('and Chair')) && (
        <>
          <h2 className="secondary-box has--textAlign--center">Department Chair</h2>
          {items
            .filter((item) => item.Description.includes('and Chair'))
            .map((item) => (
              <div className="block hero">
                <div className="block-inner-wrapper">
                  {showImage && (
                    <>
                      {!item.image_field ? (
                        <Link to={flattenToAppURL(item['@id'])} condition={!isEditMode}>
                          <Image className="portraitPhoto" src={DefaultImageSVG} alt="placeholder" />
                        </Link>
                      ) : (
                        <Image src={flattenToAppURL(`${item['@id']}/@@images/${item.image_field}/mini`)} alt={item.title && item.title} className="hero-image portraitPhoto" loading="lazy" />
                      )}
                    </>
                  )}

                  <div className="hero-body">
                    <div className="hero-text">
                      <TitleTag>
                        <Link to={flattenToAppURL(item['@id'])} condition={!isEditMode}>
                          {item.title}
                        </Link>
                      </TitleTag>

                      <p className="documentDescription">
                        {item.description.split(' -').map((part, index, array) =>
                          index === array.length - 1 ? (
                            part
                          ) : (
                            <>
                              {part}
                              <br />
                            </>
                          ),
                        )}
                      </p>
                    </div>
                    {/*<div className='link-more'>*/}
                    {/*  <Link to={flattenToAppURL(item['@id'])}>View {item.title} Profile</Link></div>*/}
                  </div>
                </div>
              </div>
            ))}
        </>
      )}
      {templateSelected === 'segmented' && items.filter((item) => !item.Description.match(/and Chair|Emeritus|Emerita/) && item.Subject.includes('Full-time')).length > 0 && (
        <>
          <h2 className="secondary-box has--textAlign--center">Full-time Faculty</h2>
          {items
            .filter((item) => !item.Description.match(/and Chair|Emeritus|Emerita/) && item.Subject.includes('Full-time'))
            .map((item) => (
              <div className="block hero">
                <div className="block-inner-wrapper">
                  {showImage && (
                    <>
                      {!item.image_field ? (
                        <Link to={flattenToAppURL(item['@id'])} condition={!isEditMode}>
                          <Image className="portraitPhoto" src={DefaultImageSVG} alt="placeholder" />
                        </Link>
                      ) : (
                        <Image src={flattenToAppURL(`${item['@id']}/@@images/${item.image_field}/mini`)} alt={item.title && item.title} className="hero-image portraitPhoto" loading="lazy" />
                      )}
                    </>
                  )}

                  <div className="hero-body">
                    <div className="hero-text">
                      <TitleTag>
                        <Link to={flattenToAppURL(item['@id'])} condition={!isEditMode}>
                          {item.title}
                        </Link>
                      </TitleTag>

                      <p className="documentDescription">
                        {item.description.split(' -').map((part, index, array) =>
                          index === array.length - 1 ? (
                            part
                          ) : (
                            <>
                              {part}
                              <br />
                            </>
                          ),
                        )}
                      </p>
                    </div>
                    {/*<div className='link-more'>*/}
                    {/*  <Link to={flattenToAppURL(item['@id'])}>View {item.title} Profile</Link></div>*/}
                  </div>
                </div>
              </div>
            ))}
        </>
      )}
      {templateSelected === 'segmented' && items.filter((item) => item.Description.match(/Emeritus|Emerita/)).length > 0 && (
        <>
          <h2 className="secondary-box has--textAlign--center">Professor Emeriti</h2>
          {items
            .filter((item) => item.Description.match(/Emeritus|Emerita/))
            .map((item) => (
              <div className="block hero">
                <div className="block-inner-wrapper">
                  {showImage && (
                    <>
                      {!item.image_field ? (
                        <Link to={flattenToAppURL(item['@id'])} condition={!isEditMode}>
                          <Image className="portraitPhoto" src={DefaultImageSVG} alt="placeholder" />
                        </Link>
                      ) : (
                        <Image src={flattenToAppURL(`${item['@id']}/@@images/${item.image_field}/mini`)} alt={item.title && item.title} className="hero-image portraitPhoto" loading="lazy" />
                      )}
                    </>
                  )}

                  <div className="hero-body">
                    <div className="hero-text">
                      <TitleTag>
                        <Link to={flattenToAppURL(item['@id'])} condition={!isEditMode}>
                          {item.title}
                        </Link>
                      </TitleTag>

                      <p className="documentDescription">
                        {item.description.split(' -').map((part, index, array) =>
                          index === array.length - 1 ? (
                            part
                          ) : (
                            <>
                              {part}
                              <br />
                            </>
                          ),
                        )}
                      </p>
                    </div>
                    {/*<div className='link-more'>*/}
                    {/*  <Link to={flattenToAppURL(item['@id'])}>View {item.title} Profile</Link></div>*/}
                  </div>
                </div>
              </div>
            ))}
        </>
      )}
      {templateSelected === 'segmented' && items.filter((item) => !item.Description.match(/and Chair|Emeritus|Emerita/) && !item.Subject.includes('Full-time') && item.Subject.includes('Part-time')).length > 0 && (
        <>
          <h2 className="secondary-box has--textAlign--center">Part-time Faculty</h2>
          <div style={{ padding: '1em 0 2em 0' }}>
            {items
              .filter((item) => !item.Description.match(/and Chair|Emeritus|Emerita/) && !item.Subject.includes('Full-time') && item.Subject.includes('Part-time'))
              .map((item) => (
                <p>
                  <Link to={flattenToAppURL(item['@id'])} condition={!isEditMode}>
                    {item.title}
                  </Link> - {item.description.split(' - ')[0]}
                </p>
              ))}
          </div>
        </>
      )}
      {templateSelected === 'segmented' && items.filter((item) => item.Subject.includes('CLT')).length > 0 && (
        <>
          <h2 className="secondary-box has--textAlign--center">College Laboratory Technician</h2>
          <div style={{ padding: '1em 0 2em 0' }}>
            {items
              .filter((item) => item.Subject.includes('CLT'))
              .map((item) => (
                <p>
                  <Link to={flattenToAppURL(item['@id'])} condition={!isEditMode}>
                    {item.title}
                  </Link> - {item.description.split(' - ')[0]}
                </p>
              ))}
          </div>
        </>
      )}
      {templateSelected === 'segmented' && items.filter((item) => item.Type === 'staff').length > 0 && (
        <>
          <h2 className="secondary-box has--textAlign--center">Staff</h2>
          <div style={{ padding: '1em 0 2em 0' }}>
            {items
              .filter((item) => item.Type === 'staff')
              .map((item) => (
                <p>
                  <Link to={flattenToAppURL(item['@id'])} condition={!isEditMode}>
                    {item.title}
                  </Link> - {item.description.split(' - ')[0]}
                </p>
              ))}
          </div>
        </>
      )}
    </>
  );
};

FacultyCVBlockTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  templateSelected: PropTypes.any,
  titleTag: PropTypes.any,
  isEditMode: PropTypes.bool,
  showImage: PropTypes.bool,
};

export default FacultyCVBlockTemplate;
