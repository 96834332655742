import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BlockDataForm } from '@plone/volto/components';
import { CSSTransition } from 'react-transition-group';


const ListingData = (props) => {
  const { data, block, blocksConfig, onChangeBlock } = props;
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, [data]);

  return (
    <CSSTransition
      in={isLoaded}
      timeout={500}
      classNames='fade'
      unmountOnExit
    >
      <div>
        <BlockDataForm
          schema={blocksConfig.listing.blockSchema(props)}
          title={data.title}
          onChangeField={(id, value) => {
            onChangeBlock(block, { ...data, [id]: value });
          }}
          formData={data}
        />
      </div>
    </CSSTransition>
  );
};

ListingData.propTypes = {
  data: PropTypes.object.isRequired,
  block: PropTypes.string.isRequired,
  blocksConfig: PropTypes.object.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default ListingData;
